<script lang="ts" setup>
const { t } = useT();
const route = useRoute();
const { open } = useNlcModals();
const { isMobile } = useDevice();
const loginGuard = useLoginGuard();
const { lobbyActive } = useGameNavigator({ enableHandlers: false });
const { data: appInitData } = useAppInitData();
const [showSidebar, toggleSidebar] = useToggle();
const { isFinOperationAvailable } = useCashHelper();
const { data: tournament } = useGetTournamentData();
const { openDefault } = usePresetPackageController();
const isMobileClient = useMediaQuery("(max-width: 767px)");
const { topEmailNotificationIsActive } = useFeaturesIsActive();

useHead({
	bodyAttrs: {
		class: { "overlay-hidden": showSidebar }
	}
});

const headerRef = ref(null);
const stepOnBoard = useState("stepOnBoard");

const { winnings } = useBalance({
	options: { cached: true, immediate: appInitData.value?.isGuest === false, watch: [() => appInitData.value?.isGuest] }
});
const isLogin = computed(() => appInitData.value?.isGuest === false);
const isShowRedeem = computed(() => appInitData.value?.gameMode === "SweepStakes" && appInitData.value?.hasDeposits);

const isRankedLeagueActive = computed(() => appInitData.value?.rankLeague?.enabled);

const visitedRaces = computed(() =>
	appInitData.value?.gameMode === "SweepStakes"
		? appInitData.value?.visitedRaces?.SweepStakes
		: appInitData.value?.visitedRaces?.TournamentPoints
);
const isShowBadgeActiveRaces = computed(
	() => !!(tournament.value?.data?.isActive && visitedRaces.value !== tournament.value?.data?.id)
);

useResizeObserver(headerRef, (entries) => {
	const entry = entries[0];
	const { height } = entry.contentRect;
	document.body.style.setProperty("--top-height", `${height}px`);
});

const handleOpenCash = () => {
	if (winnings.value <= 0) {
		window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
		window?.$store?.gaCash?.deposit?.({
			location: "header",
			step: "view_payments_info"
		});
	} else {
		window?.$cash?.$router?.push?.("/cash/deposit/");
		window?.$store?.gaCash?.deposit?.({
			location: "header",
			step: "payments_method"
		});
	}

	if (!isFinOperationAvailable.value) {
		return;
	}
	openDefault();
};

const navigateIfAuthorized = (event: MouseEvent) =>
	loginGuard({
		fail: () => {
			open("LazyOModalSignup");
			event.preventDefault();
			event.stopPropagation();
		}
	});

const handleDailyWheelClick = () =>
	loginGuard({
		success: () => {
			open("LazyOModalDailyWheel");

			dispatchGAEvent({
				event: "click_button",
				location: "left_menu",
				button_name: "dailywheel"
			});
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
</script>

<template>
	<div
		ref="headerRef"
		:class="[
			'fixed-wrapper',
			{ 'page-content-padding': topEmailNotificationIsActive },
			{ 'is-game-open': Boolean(route.query.game) }
		]"
	>
		<MEmailNotificationMessage v-if="topEmailNotificationIsActive" />

		<AHeader class="header-main" data-tid="header">
			<div class="header-flex">
				<AButton class="burger" variant="ghost" data-tid="burger-menu" @click="toggleSidebar()">
					<NuxtIcon name="20/burger" filled />
				</AButton>
				<NuxtLink to="/" @click="lobbyActive">
					<NuxtIcon name="logo/logo-desk" class="logo desktop" data-tid="logo" filled />
					<NuxtIcon name="logo/logo-mob" class="logo mobile" data-tid="logo-mob" filled />
				</NuxtLink>
				<nav class="menu-desktop">
					<NuxtLink to="/issues/popular-games/">
						<AText class="color-custom-6" variant="toledo" :modifiers="['medium']">{{ t("Games") }}</AText>
					</NuxtLink>
					<NuxtLink
						to="/tournaments/"
						:class="[{ 'has-counter': isShowBadgeActiveRaces }]"
						@click.capture="navigateIfAuthorized"
					>
						<AText :class="['color-custom-6']" variant="toledo" :modifiers="['medium']">{{
							t("Daily Tournaments")
						}}</AText>
						<ABadge v-if="isShowBadgeActiveRaces" background="var(--custom-13)" :size="14" class="counter">
							<AText class="color-custom-9" variant="ternopil" :modifiers="['bold']">1</AText>
						</ABadge>
					</NuxtLink>
					<AText
						class="color-custom-7"
						variant="toledo"
						:modifiers="['medium']"
						data-tid="header-lucky-wheel"
						@click="handleDailyWheelClick"
					>
						{{ t("Lucky Wheel") }}
					</AText>
					<LazyORewards v-if="isLogin" location-tid="desk">
						<div class="rewards">
							<NuxtImg
								src="/nuxt-img/rewards/gift.png"
								loading="lazy"
								format="webp"
								alt="gift"
								width="114"
								height="92"
							/>
							<AButton variant="primary" class="rewards-btn" :modifiers="['small']" data-tid="header-rewards">
								<AText class="color-neutral-100" :modifiers="['bold']" as="div">{{ t("Promo") }}</AText>
							</AButton>
						</div>
					</LazyORewards>
					<LazyMPrizeHub v-if="!isLogin && !isMobile" data-tid="header-prizes" />
				</nav>
			</div>
			<div class="header-flex">
				<template v-if="!isLogin">
					<AButton
						variant="outline"
						:modifiers="['small']"
						data-tid="header-login-btn"
						@click="open('LazyOModalLogin')"
					>
						<AText :modifiers="['uppercase']">{{ t("Log in") }}</AText>
					</AButton>
					<MRegisterGift data-tid="header-register-btn" @click="open('LazyOModalSignup')" />
				</template>
				<template v-else>
					<ONotificationCenter v-if="!isMobile" class="notification-center in-menu" />
					<Teleport v-if="stepOnBoard && isMobileClient" to=".modal .header">
						<OHeaderBalanceSwitcher />
					</Teleport>
					<OHeaderBalanceSwitcher v-else />
					<AAnimationPulse class="btn-buy" active color="var(--neutral-100)" :width="10">
						<AButton animation="pulse" class="cash-btn" size="lg" data-tid="header-buy-btn" @click="handleOpenCash">
							<NuxtIcon class="cash-icon" name="24/wallet" filled />
							<AText class="cash-text" variant="toledo" :modifiers="['medium', 'uppercase']">{{ t("Buy") }}</AText>
						</AButton>
					</AAnimationPulse>

					<div v-if="isRankedLeagueActive" class="vip-club-widget">
						<MVipClubLvlProgress data-tid="header-avatar" />
					</div>
					<NuxtLink v-else to="/game/" class="avatar" data-tid="header-avatar">
						<NuxtImg
							src="/nuxt-img/account/avatar.png"
							loading="lazy"
							format="webp"
							alt="avatar"
							width="38"
							height="38"
						/>
					</NuxtLink>
				</template>
			</div>
		</AHeader>
	</div>

	<MHeaderMobile :isShowBadgeActiveRaces="isShowBadgeActiveRaces" @toggle-sidebar="toggleSidebar" />
	<OSidebar
		:isLogin="isLogin"
		:showSidebar="showSidebar"
		:is-show-redeem="isShowRedeem"
		:isShowBadgeActiveRaces="isShowBadgeActiveRaces"
		:bannerPromo="appInitData?.popupPresetPackage?.promoOfferPreset"
		@toggle-sidebar="toggleSidebar"
	/>
</template>

<style scoped lang="scss">
.fixed-wrapper {
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	z-index: 2001;
	&.is-game-open {
		@include media-breakpoint-down(lg) {
			display: none;
		}
	}
}
.rewards {
	position: relative;
	margin-left: gutter(6);
	height: 100%;
	img {
		width: 46px;
		height: 46px;
		position: absolute;
		left: -20px;
		top: 50%;
		transform: translateY(-50%);
		z-index: 10;
	}

	.rewards-btn {
		--a-button-primary-background: var(--gradient-33);
		--a-button-primary-hover-background: var(--gradient-33);
		--a-button-primary-active-background: var(--gradient-33);
		padding: gutter(1) gutter(3.5);
		border: none;
		min-width: 110px;
	}
}
.header-main {
	padding: 0 gutter(4);
	min-height: 64px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid var(--custom-rgb-6);
	.avatar {
		margin-left: 16px;
	}

	@media (max-width: 1120px) {
		padding: 0 16px;

		.avatar {
			display: none;
		}
	}
	.header-flex {
		display: flex;
		align-items: center;
		.outline.small {
			margin-right: gutter(2);
		}
	}

	.burger {
		font-size: 24px;
		padding-left: 0;
	}
	.logo {
		display: flex;
		&:deep(svg) {
			width: 56px;
			margin: 0;
			height: auto;
		}

		@include media-breakpoint-up(md) {
			padding-right: 11px;

			&:deep(svg) {
				width: 95px;
			}
		}
	}
	.menu-desktop {
		display: flex;
		align-items: center;

		.router-link-active > span {
			color: var(--neutral-100);
		}
		& > :is(span, a) {
			margin-left: gutter(2.25);
			display: inline-block;
			transition: all 300ms ease-in-out;
			cursor: pointer;
			&:hover:not(.prize-hub) {
				opacity: 0.6;
			}
			@media (max-width: 1160px) {
				margin-left: gutter(1.25);
			}
		}
		.has-counter {
			position: relative;
			padding-right: gutter(2.25);
			.counter {
				position: absolute;
				top: 0;
				right: -1px;
				border-radius: 50%;
			}
		}
	}
	.vip-club-widget {
		margin-left: 16px;
	}
	@include media-breakpoint-down(lg) {
		.burger,
		.menu-desktop {
			display: none;
		}
	}
	.btn-buy {
		@include media-breakpoint-down(lg) {
			margin-left: gutter(2);
		}

		button {
			@include media-breakpoint-up(md) {
				width: 98px;
			}
		}
	}
}
.cash-btn {
	--a-button-default-padding: 8px;
	--a-button-size-lg-height: 40px;

	.cash-icon {
		font-size: 24px;
		@include media-breakpoint-up(lg) {
			margin-right: gutter(0.5);
		}
	}
	@include media-breakpoint-down(md) {
		--a-button-size-lg-width: 40px;
		.cash-text {
			display: none;
		}
	}
}
button {
	--a-button-default-hover-background: var(--custom-1);
}
.is-game-open {
	:deep(.switcher) {
		filter: blur(0px);
		opacity: 1;
		&.animate {
			animation: 2s blur ease 8s infinite;
		}
		&:hover {
			animation: none;
		}
	}

	~ .menu-mobile,
	~ .notification-center.out-of-menu {
		z-index: 2000;
	}
}
@keyframes blur {
	0%,
	100% {
		opacity: 0.8;
		filter: blur(4px);
	}
}
</style>
<style lang="scss">
@include media-breakpoint-down(md) {
	body.overlay-hidden {
		overflow: hidden;
	}
}
</style>
